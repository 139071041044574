 
.wrapper {
  display:flex;
  justify-content:center;
  align-items: center;
  height:100vh;
  width:100%;
  background:url('http://codingstella.com/wp-content/uploads/2024/01/download-5.jpeg') no-repeat;
  background-size:cover;
  background-position:center;
  animation:animateBg 5s linear infinite;
}
@keyframes animateBg {
  100% {
    filter:hue-rotate(360deg);
  }
}
.login-h2 {
  font-size:2em;
  color:#fff;
  text-align:center;
}
.login-box {
  position: relative;
  width: 400px;
  height: 450px;
  background: transparent;
  border-radius: 15px;
  border: 2px solid rgba(255,255,255,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
}
 
.input-box {
  position:relative;
  width:310px;
  margin:30px 0;
  border-bottom:1px solid #fff;
}
.input-box label {
  position:absolute;
  top:50%;
  left:5px;
  transform:translateY(-50%);
  font-size:1em;
  color:#fff;
  pointer-events:none;
  transition:.5s;
}
.input-box input:focus  ~ label,
.input-box input:valid  ~ label {
  top:-5px;
}
.input-box input {
  width:100%;
  height:50px;
  background:transparent;
  border:none;
  outline:none;
  font-size:1em;
  color:#fff;
  padding:0 35px 0 5px;
}
.input-box .icon {
  position:absolute;
  right:8px;
  top:50%;
  color: #fff;
  transform: translateY(-50%);
}
.remember-forgot {
  margin:-15px 0 15px;
  font-size:.9em;
  color:#fff;
  display:flex;
  justify-content:space-between;
}
.remember-forgot label input {
  margin-right:3px;
}
.remember-forgot a {
  color:#fff;
  text-decoration:none;
}
.remember-forgot a:hover {
  text-decoration:underline;
}
.login-button {
  width:100%;
  height:40px;
  background-color:#fff;
  border:none;
  border-radius:40px;
  cursor:pointer;
  font-size:1.2em;
  color:#000;
  font-weight:600;
}

.login-button:hover {
  background-color:#4ea9d3 !important;
  color:#fff;
}
.register-button-link {
  font-size:.9em;
  color:#fff;
  text-align:center;
  margin:25px 0 10px;
}
.register-button-link p a {
  color:#fff;
  text-decoration:none;
  font-weight:600;
}
.register-button-link p a:hover {
  text-decoration:underline;
}
@media (max-width:500px) {
  .login-box {
    width:100%;
    height:100vh;
    border:none;
    border-radius:0;
  }
  .input-box {
    width:290px;
  }
}